<template>
  <RevButton
    data-qa="go-to-my-order"
    data-test="go-to-my-order"
    full-width="responsive"
    :to="myOrdersRoute"
    :variant="variant"
  >
    {{ i18n(translations.label) }}
  </RevButton>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'

import { ROUTE_NAMES } from '@/scopes/dashboard/constants'

import translations from './GoToMyOrdersAction.translations'
import type { GoToMyOrdersActionProps } from './GoToMyOrdersAction.types'

const { variant } = withDefaults(defineProps<GoToMyOrdersActionProps>(), {
  variant: 'secondary',
})

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()

const myOrdersRoute = toNuxtLink(
  injectLocale({
    type: 'internal',
    name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF,
  }),
)
</script>
